import jsPDF from "jspdf";
import "svg2pdf.js";
import { ralewayRegular } from "./Raleway-Regular.js";
import { ralewaySemiBold } from "./Raleway-SemiBold.js";
import { ralewayHeavy } from "./Raleway-Heavy.js";
import svgData from "../components/45/svgData.json";
import { logo } from "./logo.js";

const FORMATS = {
  letter: { width: 8.5, height: 11, perPage: 16, title: "Letter" },
  legal: { width: 8.5, height: 14, perPage: 22, title: "Legal" },
  tabloid: { width: 11, height: 17, perPage: 28, title: "Tabloid" },
};

const PAGE_MARGIN = { left: 1.1, top: 1.5 };
const LABEL_GAP = 0.02;
const LABEL_WIDTH = 3.063;
const LABEL_HEIGHT = 1;
const HASH_LENGTH = 1.5;
const LOGO_WIDTH = 1.4;
const LOGO_HEIGHT = 1.2;

class ExportPDF {
  constructor(printSetInfo, records, defaultTitleStripStyle) {
    this.records = this.repeatCount(records, printSetInfo.printCount);
    this.printSetInfo = printSetInfo;
    this.defaultTitleStripStyle = defaultTitleStripStyle;
    this.format = FORMATS[printSetInfo.page];

    this.pdf = new jsPDF({
      orientation: "p",
      unit: "in",
      format: printSetInfo.page,
    });
    this.addFonts();
  }

  repeatCount(records, count) {
    return records.flatMap(record => Array(count).fill(record));
  }

  addFonts() {
    this.pdf.addFileToVFS("Raleway-Regular.ttf", ralewayRegular);
    this.pdf.addFont("Raleway-Regular.ttf", "RalewayRegular", "normal");

    this.pdf.addFileToVFS("Raleway-SemiBold.ttf", ralewaySemiBold);
    this.pdf.addFont("Raleway-SemiBold.ttf", "RalewaySemiBold", "normal");

    this.pdf.addFileToVFS("Raleway-Heavy.ttf", ralewayHeavy);
    this.pdf.addFont("Raleway-Heavy.ttf", "RalewayHeavy", "normal");
  }

  getLabelSVG(designId, primaryColor, secondaryColor, tertiaryColor) {
    let design = svgData.find((item) => item.id === designId) || svgData[1];
    return design.svg
      .replace(/\${primaryColor}/g, primaryColor)
      .replace(/\${secondaryColor}/g, secondaryColor)
      .replace(/\${tertiaryColor}/g, tertiaryColor);
  }

  calculateX(index) {
    return PAGE_MARGIN.left + (index % 2) * (LABEL_WIDTH + LABEL_GAP);
  }

  calculateY(index, type) {
    const indexOnPage = index % this.format.perPage;
    const baseY = PAGE_MARGIN.top + Math.floor(indexOnPage / 2) * (LABEL_HEIGHT + LABEL_GAP);
    const typeOffsets = { base: 0, artist: 0.55, a: 0.28, b: 0.84 };
    return baseY + (typeOffsets[type] || 0);
  }

  async addCard(record, index) {
    const { design, primaryColor, secondaryColor, tertiaryColor } = record.titleStrip || this.defaultTitleStripStyle;
    const svgString = this.getLabelSVG(design, primaryColor, secondaryColor, tertiaryColor);

    const parser = new DOMParser();
    const svgElement = parser.parseFromString(svgString, "image/svg+xml").documentElement;

    const x = this.calculateX(index);
    const y = this.calculateY(index, "base");

    await this.pdf.svg(svgElement, { x, y, width: LABEL_WIDTH, height: LABEL_HEIGHT });

    this.pdf.setFont("RalewaySemiBold").setFontSize(10.5);
    this.pdf.text(record.artist.toUpperCase().substring(0, 30), x + LABEL_WIDTH / 2, this.calculateY(index, "artist"), { align: "center" });

    this.pdf.setFont("RalewayHeavy").setFontSize(12.5);
    this.pdf.text(record.side.a.title.toUpperCase().substring(0, 30), x + LABEL_WIDTH / 2, this.calculateY(index, "a"), { align: "center" });
    this.pdf.text(record.side.b.title.toUpperCase().substring(0, 30), x + LABEL_WIDTH / 2, this.calculateY(index, "b"), { align: "center" });
  }

  addHashes(pageIndex) {
    const lineWidth = 0.0104;
    const startIndex = pageIndex * this.format.perPage;
    const endIndex = Math.min(startIndex + this.format.perPage, this.records.length);
    this.pdf.setDrawColor(0, 0, 0);

    const drawVerticalHash = (x, y1, y2) => {
      this.pdf.setLineWidth(lineWidth).line(x, y1, x, y2);
    };

    const drawHorizontalHash = (x1, x2, y) => {
      this.pdf.setLineWidth(lineWidth).line(x1, y, x2, y);
    };

    drawVerticalHash(this.calculateX(0) - lineWidth, this.calculateY(0, "base") - LABEL_GAP, this.calculateY(0, "base") - HASH_LENGTH - LABEL_GAP);
    drawVerticalHash(this.calculateX(1) - LABEL_GAP / 2, this.calculateY(1, "base") - LABEL_GAP, this.calculateY(1, "base") - HASH_LENGTH - LABEL_GAP);
    drawVerticalHash(this.calculateX(1) + LABEL_WIDTH + lineWidth, this.calculateY(1, "base") - LABEL_GAP, this.calculateY(1, "base") - HASH_LENGTH - LABEL_GAP);

    drawVerticalHash(this.calculateX(0) - lineWidth, this.calculateY(endIndex - 1, "base") + LABEL_HEIGHT + LABEL_GAP, this.calculateY(endIndex - 1, "base") + LABEL_HEIGHT + HASH_LENGTH + LABEL_GAP);
    drawVerticalHash(this.calculateX(1) - LABEL_GAP / 2, this.calculateY(endIndex - 1, "base") + LABEL_HEIGHT + LABEL_GAP, this.calculateY(endIndex - 1, "base") + LABEL_HEIGHT + HASH_LENGTH + LABEL_GAP);
    drawVerticalHash(this.calculateX(1) + LABEL_WIDTH + lineWidth, this.calculateY(endIndex - 1, "base") + LABEL_HEIGHT + LABEL_GAP, this.calculateY(endIndex - 1, "base") + LABEL_HEIGHT + HASH_LENGTH + LABEL_GAP);

    for (let i = startIndex; i < endIndex; i += 2) {
      drawHorizontalHash(this.calculateX(i) - LABEL_GAP, this.calculateX(i) - LABEL_GAP - HASH_LENGTH, this.calculateY(i, "base") + LABEL_HEIGHT + LABEL_GAP / 2);
      if (i + 1 < endIndex) {
        drawHorizontalHash(this.calculateX(i + 1) + LABEL_WIDTH + LABEL_GAP, this.calculateX(i + 1) + LABEL_WIDTH + LABEL_GAP + HASH_LENGTH, this.calculateY(i + 1, "base") + LABEL_HEIGHT + LABEL_GAP / 2);
      }
    }

    drawHorizontalHash(this.calculateX(0) - LABEL_GAP, this.calculateX(0) - LABEL_GAP - HASH_LENGTH, this.calculateY(0, "base") - LABEL_GAP + lineWidth);
    drawHorizontalHash(this.calculateX(1) + LABEL_WIDTH + LABEL_GAP, this.calculateX(1) + LABEL_WIDTH + LABEL_GAP + HASH_LENGTH, this.calculateY(1, "base") - LABEL_GAP + lineWidth);
  }

  async generatePdf() {
    const pageWidth = this.pdf.internal.pageSize.getWidth();
    const pageHeight = this.pdf.internal.pageSize.getHeight();
    const parser = new DOMParser();
    const svgLogoElement = parser.parseFromString(logo, "image/svg+xml").documentElement;

    const totalPages = Math.ceil(this.records.length / this.format.perPage);

    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      if (pageIndex > 0) {
        this.pdf.addPage();
      }

      await this.addLogo(svgLogoElement, pageWidth);

      this.addPageHeader(pageWidth, pageHeight);

      const startIndex = pageIndex * this.format.perPage;
      const endIndex = Math.min(startIndex + this.format.perPage, this.records.length);

      for (let i = startIndex; i < endIndex; i++) {
        await this.addCard(this.records[i], i % this.format.perPage);
      }

      this.addHashes(pageIndex);
    }

    this.pdf.save(`${this.printSetInfo.title} (${this.format.title}).pdf`);
  }

  async addLogo(svgLogoElement, pageWidth) {
    await this.pdf.svg(svgLogoElement, {
      x: pageWidth - LOGO_WIDTH - 1.5,
      y: 0.1,
      width: LOGO_WIDTH,
      height: LOGO_HEIGHT,
    });
  }

  addPageHeader(pageWidth, pageHeight) {
    this.pdf.setFont("RalewaySemiBold").setFontSize(16);
    this.pdf.text(this.printSetInfo.title, 1.25, 0.4);

    this.pdf.setFont("RalewayRegular").setFontSize(13);
    this.pdf.text(`Paper: ${this.format.title} (${this.format.width}" x ${this.format.height}") 70lb`, 1.25, 0.7);
    this.pdf.text(`Count: ${this.records.length}`, 1.25, 0.97);

    this.pdf.setFont("RalewayRegular").setFontSize(11);
    this.pdf.text(`https://jukebox.tools/`, pageWidth - 2, pageHeight - 0.25, { align: "right" });
  }
}

export default ExportPDF;
