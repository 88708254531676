<template>
  <div v-if="records.length">
    <div v-for="(recordSet, setIndex) in recordsWithRepetition" :key="setIndex" class="gridset">
      <div class="jb-grid-container">
        <div v-for="(record, stripIndex) in recordSet" :key="stripIndex" class="jb-grid-item">
          <Card :artist="record.artist" :sideA="record.side.a.title" :sideB="record.side.b.title"
            :customize="record.titleStrip" :record="record" :toolbar="toolbarOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card";
import { chunkArray } from "@/components/designer/utils";
import { removeRecordFromPrintSet } from "@/requests";

export default {
  name: "grid45",
  props: {
    records: {
      type: Array,
      required: true,
    },
    printSetInfo: {
      type: Object,
      required: true,
    },
    repeat: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      toolbarOptions: [
        {
          name: "trash",
          icon: "trash",
          onClick: (record) => {
            removeRecordFromPrintSet(record.printSetId)
            this.$emit("remove", record.printSetId)
          },
        },
      ]
    }
  },
  computed: {
    recordsWithRepetition() {
      const STRIPS_PER_PAGE = 26
      return chunkArray(this.records.flatMap(record => Array(this.repeat).fill(record)), STRIPS_PER_PAGE)
    },
  },

  components: {
    Card
  },
};
</script>

<style scoped>
.jb-grid-container {
  margin-left: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 3.0625in);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  width: 6.5in;
}

.jb-grid-item {
  width: 3.0625in;
  height: 96.015px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridset {
  position: relative;
  width: 6.5in;
}
</style>
